.main{
    background-color: #e4e4e4;
    min-height: calc(100vh - 124.81px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.container{
    width: 65%;
}

.card{
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgb(138, 138, 138);
    padding: 30px;
    display: flex;
    justify-content: center;
    margin: 30px auto;
    background-color: #ffffff;
}

.input{
    width: calc(100% - 40px);
    outline: 0px;
    border: 0px;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #E4E4E4;
}

.button{
    border-radius: 10px;
    background-color: #2BCFCE;
    color: #ffffff;
    padding: 10px 20px;
    outline: 0px;
    border: 0px;
    width: 100%;
    cursor: pointer;
}

.input_container{
    display: grid;
    grid-template-columns: 0.8fr 0.2fr;
    grid-gap: 20px;
    width: 90%;
}

.link_container{
    border: 1px solid #c5c5c5;
    /* padding: 15px 5px; */
    display: grid;
    grid-template-columns: 0.6fr 0.3fr 0.1fr;
    border-radius: 5px;
    margin: 15px;
}

.link_card{
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgb(138, 138, 138);
    padding: 30px;
    justify-content: center;
    margin: 30px auto;
    background-color: #ffffff;
}

.link{
    padding: 15px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.shorten_link{
    color:#00b9b8;
    text-align: end;
    padding: 15px 10px;
}

.copy_button{
    border-radius: 5px;
    color: white;
    background-color: #2BCFCE;
    outline: 0px;
    border: 0px;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.copy_button_container{
    padding: 5px;
}

.loader_backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}