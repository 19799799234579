.main{
    color: #2F2F2F;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 10px black;
    z-index: 10;
}

.container{
    width: 80%;
    display: flex;
    justify-content: space-between;
}

.navigation{
    display: flex;
    list-style-type: none;
    align-items: center;
}

.navigation li{
    padding: 0 20px;
    font-size: 18px;
    margin: 0 10px;
}

.navigation a{
    text-decoration: none;
}

.navigation .active a{
    color: #2BCFCE;
    text-decoration: underline;
}